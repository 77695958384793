import { graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import * as styles from "../styles/404.module.css"
import * as homeStyles from "../styles/home.module.css"
import * as cardStyles from "../styles/plants.module.css"

export default function NotFound({ data }) {

  const plants = data.allPlantsDataJson.nodes

  return (
    <Layout>
      <Seo title='Pianta non trovata' description='Scopri altre piante nei giardini delle driadi.' article={false} />

      <div className={styles.container}>
        <div className={styles.padded}>
          <h2 className={styles.title}>404</h2>
          <p className={styles.subtitle}>Questa pagina non esiste</p>

          <h3 className={homeStyles.explore}>Scopri altre piante</h3>

          <div className={homeStyles.plantsContainer}>
            {plants.map(plant => {
              return (<Link to={'/piante/' + plant.slug} key={plant.plantId}>
                <div className={cardStyles.card}>
                  {plant.image.childImageSharp &&
                    <GatsbyImage image={plant.image.childImageSharp.gatsbyImageData} className={cardStyles.image} src={plant.image} alt={plant.name} />
                  }
                  <p className={cardStyles.text}>{plant.name}</p>
                </div>
              </Link>)
            })}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
query NotFoundPlants {
  allPlantsDataJson(limit: 5, filter: {caratteristiche: {ne: ""}}) {
      nodes {
        slug
        name
        plantId
        image {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED)
          }
        }  
      }
    }
  }
`